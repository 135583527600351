import React, { useEffect } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import { Spinner } from "reactstrap";
import { css } from "emotion";

import "./App.css";

import history from "./utils/history";
import NavigationBar from "./components/navigation/NavigationBar";
import Companies from "./screens/companies/Companies";
import Profile from "./screens/profile/Profile";
import { DiscoverNewWrapper } from "./components/DiscoverNew/DiscoverNewWrapper";
import { ProjectsWrapper } from "./screens/projects/ProjectsWrapper";
import { AcceptInvite } from "./screens/projects/AcceptInvite/AcceptInvite";
import Project from "./screens/project/project";
import { useAuthController } from "./contexts/AuthProvider";
import { landingUrlTo } from "./components/DiscoverNew/util/UrlHelper";
import { SubscriptionRequired } from "./components/auth/SubscriptionRequired/SubscriptionRequired";
import { SubscriptionVerification } from "./components/auth/SubscriptionVerification/SubscriptionVerification";
import Admin from "./screens/admin/admin";
import { AdminUser } from "./screens/admin/AdminUser/AdminUser";
import { CandidatesPage } from "./components/candidate/v2/CandidatesPage";
import { ImpersonatedBanner } from "./components/navigation/ImpersonatedBaner/ImpersonatedBanner";
import { PaymentProcessing } from "./components/auth/PaymentProcessing/PaymentProcessing";
import { AdminOrganization } from "./screens/admin/AdminOrganization/AdminOrganization";
import { PermissionEnum, permissionService } from "./screens/projects/PermissionService";
import { CandidateImport } from "./components/candidate/v2/CandidateImport/CandidateImport";

window.__chatterworks__appVersion = "2.0.17";

// console.log(Sentry);

// Sentry.init({
//   dsn: "https://5bfd9b58b25eb8d8a41c7616255659d6@o4505799212007424.ingest.sentry.io/4505799214039040",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ["localhost", "https://chatterworks.com"],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

function RedirectToLanding() {
  useEffect(() => {
    window.location.href = landingUrlTo("/sign-in");
  }, []);
  return null;
}

// let accessToken = authService.getTokenData();
// if (!accessToken) {
//   accessToken = prompt('Enter token');
//   authService.setTokenData(null, {
//     access_token: accessToken,
//   })
// }

const App = () => {
  const { isAuthenticated, isImpersonated, loading, user } = useAuthController();

  if (loading) {
    return (
      <div style={{ padding : "200px 0", display : "flex", alignItems : "center", justifyContent : "center" }}>
        <Spinner color="primary" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <Router history={history}>
        {isImpersonated && <ImpersonatedBanner />}
        <NavigationBar />
        <Switch>
          <Route path="/profile/:section?" component={Profile} />
          <Route path="/accept-invite">
            <div
              id="app-container"
              className={css`
                min-height: 100vh;
                height: 100%;
              `}
            >
              <AcceptInvite />
            </div>
          </Route>
          <RedirectToLanding />
        </Switch>
      </Router>
    );
  }

  if (!user || !user.hasActiveSubscription) {
    return (
      <Router history={history}>
        <div
          id="app-container"
          className={css`
            min-height: 100vh;
            height: 100%;
          `}
        >
          {isImpersonated && <ImpersonatedBanner />}
          <NavigationBar />
          <Switch>
            {/*<Route path="/profile/:section?" component={Profile} />*/}
            <Route path="/payment-processing" component={PaymentProcessing} />
            <Route path="/accept-invite" component={AcceptInvite} />
            <Route path="/subscription-required" component={SubscriptionRequired} />
            <Route path="/subscription-verification" component={SubscriptionVerification} />
            <Redirect to="/subscription-required" />
          </Switch>
        </div>
      </Router>
    );
  }

  const canDiscover = permissionService.can(null, PermissionEnum.discover);

  return (
    <Router history={history}>
      <div
        id="app-container"
        className={css`
          min-height: 100vh;
          height: 100%;
        `}
      >
        {isImpersonated && <ImpersonatedBanner />}
        {/*<LowCreditsBanner />*/}
        <NavigationBar />
        <Switch>
          <Route path="/payment-processing" component={PaymentProcessing} />
          <Route path="/accept-invite" component={AcceptInvite} />
          {canDiscover && (
            <Route path={["/discover-new/:candidateId", "/discover-new"]} component={DiscoverNewWrapper} />
          )}
          <Route path="/profile/:section?" component={Profile} />
          <Route path={["/my-candidates/:projectId?"]} component={CandidatesPage} />
          {canDiscover && (
            <Route path={["/discover/:candidateId", "/discover", "/candidates/:candidateId", "/candidates"]}
                 component={DiscoverNewWrapper} />
          )}
          <Route path="/companies" component={Companies} />
          <Route path="/projects" exact component={ProjectsWrapper} />
          <Route path="/projects/:id" component={Project} />
          <Route path="/import-candidates" component={CandidateImport} />
          {user.isAdmin ? (
            <Switch>
              <Route path="/admin/users/:userId" component={AdminUser} />
              <Route path="/admin/organizations/:organizationId" component={AdminOrganization} />
              <Route path="/admin" component={Admin} />
              <Redirect to={canDiscover ? '/discover' : '/projects'} />
            </Switch>
          ) : <Redirect to={canDiscover ? '/discover' : '/projects'} />}
        </Switch>
      </div>
    </Router>
  );
};

export default App;
